import { chainflipCommunityLinks } from '@chainflip/utils/consts';
import { capitalize } from '@chainflip/utils/string';
import { Link } from '@/shared/components';
import { useGqlQuery } from '@/shared/hooks/useGqlQuery';
import { DiscordIcon, TelegramIcon, TwitterIcon } from '@/shared/icons/large';
import { getChainflipNetwork } from '@/shared/utils';
import { getRuntimeVersionQuery } from '../graphql/cache/environment';

export const Footer = () => {
  const { data } = useGqlQuery(getRuntimeVersionQuery, {
    context: { clientName: 'statechainCache' },
  });

  return (
    <div className="mt-[100px] flex h-[152px] w-full justify-center border-t border-cf-gray-4 text-14 text-white lg:h-[75px]">
      <div className="flex flex-col items-center justify-center space-y-4 lg:container lg:flex-row lg:justify-between lg:space-y-0 lg:py-6">
        <div className="font-aeonikMono text-14">
          {capitalize(getChainflipNetwork())} v
          {data?.allEnvironmentAddresses?.nodes[0].runtimeVersion.toString().split('').join('.')}
        </div>
        <div className="flex items-center space-x-8 [&>img]:stroke-white">
          <Link href={chainflipCommunityLinks.discord} target="_blank">
            <DiscordIcon />
          </Link>
          <Link href={chainflipCommunityLinks.telegram} target="_blank">
            <TelegramIcon />
          </Link>
          <Link href={chainflipCommunityLinks.twitter} target="_blank">
            <TwitterIcon />
          </Link>
          <div className="hidden lg:block">{new Date().getFullYear()}, Chainflip Labs</div>
        </div>
      </div>
    </div>
  );
};
