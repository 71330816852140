import React from 'react';
import classNames from 'classnames';
import { type ChainflipAsset } from '@/shared/graphql/generated/explorer/graphql';
import { TokenAmountWithChainByAsset } from './TokenWithChain';
import RightDouble from '../icons/flip-ui-kit/small/RIghtDouble';

export type SwapStep = { asset: ChainflipAsset; amount?: string; amountUsd?: string };

export const SwapRouteArrow = ({ className }: { className?: string }) => (
  <div
    className={classNames(
      'relative m-auto h-[24px] w-[24px] rounded-full border-[0.75px] border-cf-gray-3-5 p-1 shadow-[0px_0px_0px_0.75px_#191919] [background:linear-gradient(180deg,rgba(255,255,255,0.06)_-19.26%,rgba(255,255,255,0.00)_60.89%),#252525]',
      className,
    )}
  >
    <RightDouble className="absolute bottom-[2.3px] right-[3px] text-cf-light-2" />
  </div>
);

export const SwapSteps = ({
  steps,
}: {
  steps: [SwapStep, SwapStep] | [SwapStep, SwapStep, SwapStep];
}) => (
  <div className="@container">
    <div
      className={classNames(
        'grid grid-cols-2 items-center justify-between gap-3',
        steps.length === 3 && !steps.every((step) => step.amount === undefined)
          ? '@[600px]:grid-flow-col @[600px]:grid-cols-none'
          : '@[300px]:grid-flow-col @[300px]:grid-cols-none',
      )}
    >
      {steps.map((step, index) => (
        <React.Fragment key={step.asset}>
          <div>
            <TokenAmountWithChainByAsset
              asset={step.asset}
              amount={step.amount}
              usdAmount={step.amountUsd}
              amountClass="text-12 lg:text-14 text-white whitespace-nowrap"
              displayChainLogo={!(index === 1 && steps.length > 2)}
            />
          </div>

          {index + 1 !== steps.length && (
            <div
              className={classNames(
                'justify-self-end',
                steps.length === 3 ? '@[600px]:justify-self-auto' : '@[300px]:justify-self-auto',
              )}
            >
              <SwapRouteArrow />
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  </div>
);
